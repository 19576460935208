import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

export enum OrderLineWarehouseItemMixinTags {
  INDEX = 'OrderLineWarehouseItemsIndex',
  SHOW = 'OrderLineWarehouseItemShow',
  CREATE = 'OrderLineWarehouseItemCreate',
  UPDATE = 'OrderLineWarehouseItemUpdate',
  DESTROY = 'OrderLineWarehouseItemDestroy',
}

const BASE_ORDER_LINE_WAREHOUSE_ITEM_URL = BASE_API_URL + "order_line_warehouse_items"

@Component
export default class OrderLineWarehouseItemsMixin extends Vue {
  getOrderLineWarehouseItems(data: any, tag: string) {
    return this.$httpGetQuery(BASE_ORDER_LINE_WAREHOUSE_ITEM_URL, tag, data);
  }

  getOrderLineWarehouseItem(id: string, tag: string) {
    return this.$httpGet(BASE_ORDER_LINE_WAREHOUSE_ITEM_URL+"/"+id, tag);
  }

  createOrderLineWarehouseItem(data: any, tag: string) {
    const url = BASE_ORDER_LINE_WAREHOUSE_ITEM_URL;
    return this.$httpPost(url, tag, data);
  }

  updateOrderLineWarehouseItem(id: string, data: any, tag: string) {
    const url = BASE_ORDER_LINE_WAREHOUSE_ITEM_URL + "/" + id;
    return this.$httpPut(url, tag, data);
  }

  destroyOrderLineWarehouseItem(id: string, tag: string) {
    const url =  BASE_ORDER_LINE_WAREHOUSE_ITEM_URL + "/" + id;
    return this.$httpDelete(url, tag);
  }
}
