













import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import { RequestData } from "@/store/types/RequestStore";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { RegisterHttp } from "@/utils/Decorators";
import StoreFrontCategoryGroupsMixin, { StoreFrontCategoryGroupsMixinTags } from "@/mixins/http/StoreFrontCategoryGroupsMixin";
import { StoreFrontCategoryGroup } from "@/models";

const tag = 'get_source_tag';

@Component({ components: { VSelect } })
export default class StoreFrontCategoryGroupSelect extends Mixins(StoreFrontCategoryGroupsMixin) {
  @Prop()
  selected!: string | null;

  @Prop()
  queryKey?: string;

  @Prop()
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  @Prop({ default: false })
  actsAsFilter: boolean;

  @Prop()
  filter: any;

  options: VSelectOption[] = [];

  private value: string | null = this.selected || null;

  @RegisterHttp(tag) readonly request!: RequestData;

  mounted() {
    this.loadItems();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100
      }
    };

    if (this.filter) {
      data.filter = this.filter;
    }

    return data;
  }

   @Watch('selected')
  onSelectedChanged(selected: string) {

    this.value = selected;
    this.onItemsReady();
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }

  get loading() {
    return this.request?.loading;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected.toString())?.id;
    }
  }

  async loadItems() {
    if (this.actsAsFilter) {
      this.options.unshift(this.filterDefault);
    }

    const [data, errors] = await easync(
      this.getStoreFrontCategoryGroups(this.payload, tag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.options = data.map((o: StoreFrontCategoryGroup) => {
      return { id: o.id, text: `${o.storeFront.name} - ${o.categoryGroup.name}` };
    });
    this.onItemsReady();
  }
}
