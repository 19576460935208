














































































































































import { Mixins, Component, Prop, Ref } from "vue-property-decorator";

import { Design, Order, OrderLine } from "@/models";

import DesignsModal from "@/components/OrderLine/DesignsModal.vue";
import AddDesignModal from "@/components/OrderLine/AddDesignModal.vue";
import { currentUserStore } from "@/store/typed";
import RoundThumb from "@/components/RoundThumb.vue";
import { easync } from "@/utils/http";

import OrderLinesMixin, {
  OrderLineMixinTags,
} from "@/mixins/http/OrderLinesMixin";
import { showErrorToast } from "@/utils/Toast";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import OrderLineWarehouseItemItem from "./WarehouseItem.vue";
import OrderLineWarehouseItemsMixin, {
  OrderLineWarehouseItemMixinTags,
} from "@/mixins/http/OrderLineWarehouseItemsMixin";
import OrderLineWarehouseItem from "@/models/OrderLineWarehouseItem";

@Component({
  components: {
    RoundThumb,
    DesignsModal,
    AddDesignModal,
    OrderLineWarehouseItemItem,
  },
})
export default class OrderLineItem extends Mixins(
  OrderLinesMixin,
  OrderLineWarehouseItemsMixin
) {
  @Prop({ required: true }) readonly value!: OrderLine;

  private item: OrderLine | null = this.value || null;
  private warehouseItems: OrderLineWarehouseItem[] = [];

  @Ref() designsModal: DesignsModal;
  @Ref() addDesignModal: AddDesignModal;

  @RegisterHttp(OrderLineMixinTags.UPDATE) readonly updateRequest!: RequestData;
  @RegisterHttp(OrderLineMixinTags.REIMPORT_DESIGNS)
  readonly reimportRequest!: RequestData;
  @RegisterHttp(OrderLineWarehouseItemMixinTags.INDEX)
  readonly whareHouseItemsRequest!: RequestData;

  private showWarehouseItems = false;

  get currentUser() {
    return currentUserStore.currentUser;
  }

  get updateOrderTag() {
    return `${OrderLineMixinTags.UPDATE}`;
  }

  get loading() {
    return this.updateRequest?.loading;
  }

  get reimportDesignsLoading() {
    return this.reimportRequest?.loading;
  }

  get warehouseItemsLoading() {
    return this.whareHouseItemsRequest?.loading;
  }

  url(item: OrderLine) {
    const designs = item.designs;
    return designs ? designs[0]?.url : "";
  }

  private toggleShowWarehouseItems() {
    this.showWarehouseItems = !this.showWarehouseItems;
    if (this.showWarehouseItems && this.warehouseItems.length === 0) {
      this.loadWarehouseItems();
    }
  }

  private async loadWarehouseItems() {
    const filter = {
      filter: { orderLine: this.item.id },
    };

    const [data, errors] = await easync(
      this.getOrderLineWarehouseItems(
        filter,
        OrderLineWarehouseItemMixinTags.INDEX
      )
    );

    if (data) {
      this.warehouseItems = data;
    } else {
      this.warehouseItems = [];
    }
  }

  get printStatusIcon() {
    return this.item.printStatus === "printed"
      ? "check-circle"
      : "exclamation-circle";
  }

  get printStatusClass() {
    return this.item.printStatus === "printed"
      ? "text-success"
      : "text-warning";
  }

  get importStatusIcon() {
    switch (this.item.importStatus) {
      case "imported":
        return "check-circle";
      case "import_error":
      case "to_import":
        return "exclamation-circle";
    }
    return "";
  }

  get importStatusClass() {
    switch (this.item.importStatus) {
      case "imported":
        return "text-success";
      case "import_error":
        return "text-danger";
      case "to_import":
        return "text-warning";
    }
    return "";
  }

  private openDesignsModal() {
    if (!(this.item.importer === "zakeke")) {
      return;
    }
    if (this.item?.designs?.length) {
      (this.designsModal as any).open(this.item);
    } else {
      (this.addDesignModal as any).open(this.item);
    }
  }

  onApproved() {
    this.changeApprovedStatus();
  }

  async changeApprovedStatus() {
    const payload = {
      id: this.item.id,
      approved: this.item.approved ? "0" : "1",
    };

    const [data, errors] = await easync(
      this.updateOrderLine(payload, this.updateOrderTag)
    );
    if (errors) {
      showErrorToast("C'è stato un errore");
    }else {
      this.item.approved = data.approved
    }

    this.$emit("approvedStatusUpdate");
  }

  onDesignsChanged() {
    this.$emit("designChanged", this.item);
  }

  onOrderChanged(order: Order) {
    this.$emit("orderChanged", order);
  }

  onDesignAdded(orderLine: OrderLine) {
    this.item = orderLine;
    this.$emit("designAdded", this.item);
  }

  async reimport(value: OrderLine) {
    const [data, errors] = await easync(
      this.reimportDesigns(value.id, {}, OrderLineMixinTags.REIMPORT_DESIGNS)
    );
    if (errors) {
      showErrorToast("C'è stato un errore");
      return;
    }
    this.$emit("reimported");
  }

  private onAddNewItem() {
    this.warehouseItems.push(new OrderLineWarehouseItem());
  }
}
