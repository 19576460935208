import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

const BASE_PRINT_SIDE_URL = BASE_API_URL+"print_sides"


@Component
export default class PrintSidesMixin extends Vue {
  getPrintSides(data: any, tag: string) {
    return this.$httpGetQuery(
      BASE_PRINT_SIDE_URL,
      tag,
      data
    );
  }

  getPrintSide(id: number, tag: string) {
    return this.$httpGet(
      BASE_PRINT_SIDE_URL+"/"+id,
      tag
    );
  }

  destroyPrintSide(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRINT_SIDE_URL+"/"+id, tag);
  }

  createPrintSide(data: any, tag: string) {
    return this.$httpPost(BASE_PRINT_SIDE_URL, tag, data);
  }

  updatePrintSide( data: any, tag: string) {
    return this.$httpPut(
      BASE_PRINT_SIDE_URL+"/"+data.id,
      tag,
      data
    );
  }
}
