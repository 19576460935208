import { render, staticRenderFns } from "./Design.vue?vue&type=template&id=35a58cfe&scoped=true&"
import script from "./Design.vue?vue&type=script&lang=ts&"
export * from "./Design.vue?vue&type=script&lang=ts&"
import style0 from "./Design.vue?vue&type=style&index=0&id=35a58cfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a58cfe",
  null
  
)

export default component.exports