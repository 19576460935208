



































































import { Component, Mixins, Prop } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import { OrderLine, Source } from "@/models";
import { namespace } from "vuex-class";
import OrderLineDesign from "@/components/OrderLine/Design.vue";
import { NoCache } from "@/utils/Decorators";
import OrdersMixin from '@/mixins/http/OrdersMixin';
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { Order } from "@/models";
import { showToast } from "@/utils/Toast";
import { Design } from "@/models";
import { sourceStore } from "../../store/typed";

const updateTag = "update_order";

@Component({
  components: {
    VModal,
    OrderLineDesign
  }
})
export default class OrderLineDesignsModal extends Mixins(OrdersMixin) {

  @RegisterHttp(updateTag) updateRequest?: RequestData;

  modal!: VModal;
  private orderLine: OrderLine = null;
  @Prop() readonly order: Order;

  discardToConfirm = false;

  private index = 0;

  onDesignsChanged(orderLine: OrderLine) {
    this.orderLine = orderLine;
    this.$emit("changed", orderLine);
    if(!orderLine.designs){
      this.modal.hide();
    }
  }

  get source() {
    return sourceStore.currentSource;
  }

  get needsApproval() {
    return this.source?.needsApproval
  }

  get images() {
    return (this.orderLine?.designs||[])?.map(o => o.url) || [];
  }

  confirmDiscard() {
    this.discardToConfirm = true;
  }

  get canDiscard() {
    return this.order.approvedStatus !== 'discarded'
  }

  onAdd() {
    const emptyDesign = new Design();
    this.orderLine.designs.push(emptyDesign);
    this.index = this.orderLine.designs.length-1
  }

  async discardOrder() {

    const data = { id: this.orderLine.orderId, approvedStatus: 'discarded'};
    const response = await this.updateOrder(data, updateTag);
    this.$emit('orderChanged', response);
    this.onCancel();
    showToast('Ordine scartato!')
  }

  get currentIndex() {
    return Math.abs(this.index) % this.images.length;
  }

  get currentImage() {
    return this.images[Math.abs(this.index) % this.images.length];
  }

  @NoCache
  get currentDesign(): any {
    return this.orderLine?.designs ?  this.orderLine?.designs[Math.abs(this.index) % this.orderLine?.designs?.length] : [];
  }

  next() {
    this.index++;
  }

  prev() {
    this.index--;
  }

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  onHidden() {
    this.orderLine = null;
    this.index = 0;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    this.modal.hide();
  }

  onApproved() {
    this.$emit("approved", this.orderLine.id);
    this.modal.hide();
  }

  protected onConfirm() {
    this.modal.hide();
  }

  get discardingOrder() {
    return this.updateRequest?.loading
  }

  open(orderLine: OrderLine) {
    if (orderLine) {
      this.orderLine = orderLine;
    }
    this.modal.show();
  }
}
