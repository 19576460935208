
















import { Component, Prop, Vue } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import OrderLines from '@/components/OrderLine/List.vue';
import { Order } from '@/models';

@Component({
  components: {
    VModal,OrderLines
  }
})
export default class ConfirmModal extends Vue {
  @Prop({ default: "Linee d\'ordine"}) title? : string;

  modal!: VModal;
  value: Order = null;

  mounted() {
		this.modal = this.$refs.modal as VModal;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {

    this.modal.hide();
  }

  show(value: Order) {
    this.modal.show();
    this.value = value
  }
}
