import { BASE_API_URL } from "@/utils/Constants";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";

export enum OrderLineMixinTags {
  INDEX = 'OrderLinesIndex',
  SHOW = 'OrderLineShow',
  CREATE = 'OrderLineCreate',
  UPDATE = 'OrderLineUpdate',
  REIMPORT_DESIGNS = 'OrderLineReimportDesigns'
}

const BASE_ORDER_LINE_URL = BASE_API_URL+"order_lines"

@Component
export default class OrderLinesMixin extends Vue {
  updateOrderLine(data: any, tag: string) {
    return this.$httpPut(
      BASE_ORDER_LINE_URL+"/"+data.id,
      tag,
      data
    );
  }

  getOrderLine(id: string, tag: string) {
    return this.$httpGet(BASE_ORDER_LINE_URL+"/"+id, tag);
  }

  getOrderLines(data: any, tag: string) {
    return this.$httpGetQuery(BASE_ORDER_LINE_URL, tag, data);
  }

  createDesign(id: string,data: any, tag: string) {
    const query =  qs.stringify(data, { arrayFormat: "brackets" })
    const url =  BASE_ORDER_LINE_URL+"/"+id+"/create_design?"+query
    return this.$httpPost(
     url,
      tag,
      data
    );
  }

  replaceDesign(id: string, data: any, tag: string) {
    const query =  qs.stringify(data, { arrayFormat: "brackets" })
    const url =  BASE_ORDER_LINE_URL+"/"+id+"/replace_design?"+query
    return this.$httpPut(
      url,
      tag,
      data
    );
  }

  destroyDesign(id: string, data: any, tag: string) {
    const query =  qs.stringify(data, { arrayFormat: "brackets" })
    const url =  BASE_ORDER_LINE_URL+"/"+id+"/destroy_design?"+query

    return this.$httpDelete(
     url,
      tag
          );
  }

  reimportDesigns(id: string, data: any, tag: string) {
    return this.$httpPost(
      BASE_ORDER_LINE_URL+"/"+id+"/reimport_designs",
      tag,data
          );
  }
}
