








































































import { Component, Prop, Mixins,Watch } from "vue-property-decorator";
import FileUploader from "@/components/FileUploader.vue";
import { RequestData } from "@/store/types/RequestStore";
import { RegisterHttp } from "@/utils/Decorators";
import { showErrorToast, showToast } from "@/utils/Toast";
import OrderLinesMixin from "@/mixins/http/OrderLinesMixin";
import PrintSideSelect from "@/components/PrintSide/Select.vue";
import AddDesignForm from "@/components/OrderLine/AddDesignForm.vue";
import { Design } from "@/models";
import { OrderLine } from "@/models";
import { NoCache } from "@/utils/Decorators";
import { easync } from "@/utils/http";

const replaceTag = "replace_design";
const createTag = "create_design";
const destroyTag = "destroy_design";

@Component({
  components: {
    FileUploader,
    PrintSideSelect,
    AddDesignForm,
  },
})
export default class OrderLineDesign extends Mixins(OrderLinesMixin) {
  @Prop({ required: true }) readonly value!: Design;
  @Prop({ required: true }) readonly editable: boolean;
  @Prop({ required: true }) readonly orderLine: OrderLine;
  @Prop({ required: true }) readonly productId: string;
  @RegisterHttp(replaceTag) replaceRequest: RequestData;
  @RegisterHttp(createTag) createRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;

  private file: any = null;
  private design: Design = this.value;

  @Watch('value')
  onValueChanged(value: Design){
    this.design = value;
  }

  get replaceLoading() {
    return this.replaceRequest?.loading;
  }

  get createLoading() {
    return this.createRequest?.loading;
  }

  get destroyLoading() {
    return this.destroyRequest?.loading;
  }

  get orderLineId() {
    return this.orderLine.id
  }

  get printSideId() {
    return this.design?.printSideId?.toString();
  }

  onDesignAdded(orderLine: OrderLine){
    this.$emit("changed", orderLine);
  }

  onPrintSideSelected(id: string) {
    this.design.printSideId = id;
    this.replace();
  }

  async replace() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("delete_id", this.design.id);
      formData.append("print_side_id", this.design.printSideId);

      if (this.file?.file) {
        formData.append("design", this.file.file);
      }

      const [data, errors] = await easync(this.replaceDesign(this.orderLineId, formData, replaceTag));
      if (errors) {
        showErrorToast("Si è verificato un errore");
        return;
      }

      this.file = null;
      showToast("Design modificato!");
      this.$emit("changed", data);
    });
  }

 @NoCache
  get creating() {
    return !this.design.id;
  }

  async create() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("design", this.file.file);

      const [data, errors] = await easync(this.createDesign(this.orderLineId, formData, createTag));
      if (errors) {
        showErrorToast("Si è verficato un errore");
        return;
      }

      this.file = null;
      showToast("Design creato!");
      this.$emit("changed", data);
    });
  }

  async destroy() {
    await this.$nextTick();

    const [data, errors] = await easync(
      this.destroyDesign(this.orderLineId, { printable_id: this.design.id }, destroyTag)
    );

    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.file = null;
    showToast("Design eliminato!");
    this.$emit("changed", data);
  }

  async upload() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("delete_id", this.design.id);
      formData.append("design", this.file.file);

      const [data, errors] = await easync(this.replaceDesign(this.orderLineId, formData, replaceTag));
      if (errors) {
        showErrorToast("Si è verificato un errore");
        return;
      }

      this.file = null;
      showToast("Design modificato!");
      this.$emit("changed", data);
    });
  }

  get url() {
    return this.file?.url || this.design?.url;
  }
}
