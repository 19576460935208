






































































import { Vue, Component, Mixins, Ref } from 'vue-property-decorator';

import { StoreFrontCategoryGroup, StoreFrontSection } from '@/models';
import { StoreFrontSectionPayload } from '@/models/StoreFrontSection';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import StoreFrontSectionPageCodeSelect from '@/components/StoreFront/Section/PageCodeSelect.vue';
import WithErrors from '@/directives/WithErrors';
import StoreFrontCategoryGroupList from '@/components/StoreFront/CategoryGroup/List.vue';
import StoreFrontCategoryGroupSelect from '@/components/StoreFront/CategoryGroup/Select.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';

import StoreFrontSectionsMixin, { StoreFrontSectionsMixinTags } from '@/mixins/http/StoreFrontSectionsMixin';
import StoreFrontCategoryGroupsMixin, { StoreFrontCategoryGroupsMixinTags } from '@/mixins/http/StoreFrontCategoryGroupsMixin';

@Component({
  components: {
    StoreFrontSectionPageCodeSelect,
    StoreFrontCategoryGroupList,
    StoreFrontCategoryGroupSelect,
    ConfirmModal
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontContentSectionShow extends Mixins(StoreFrontSectionsMixin, StoreFrontCategoryGroupsMixin) {
  private section: StoreFrontSection | null = null;
  private payload: StoreFrontSectionPayload | null = null;
  private selectedCategoryGroup = '';

  @Ref() readonly confirmModal!: ConfirmModal;
  @Ref() readonly categoryGroupList!: StoreFrontCategoryGroupList;
  @Ref() readonly categoryGroupSelect!: StoreFrontCategoryGroupSelect;

  async created() {
    await this.loadSection();
    this.feedPayload();
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get sectionId() {
    return this.$route.params.sectionId;
  }

  get showTag() {
    return `${StoreFrontSectionsMixinTags.Show}_${this.sectionId}`;
  }

  get updateTag() {
    return `${StoreFrontSectionsMixinTags.Update}_${this.sectionId}`;
  }

  get storeFrontCategoryGroupsFilterWithoutSection() {
    return {
      storeFront: this.storeFrontId,
      withoutSection: true
    }
  }

  get storeFrontCategoryGroupsFilterWithSection() {
    return {
      storeFront: this.storeFrontId,
      section: this.sectionId
    }
  }

  private feedPayload() {
    this.payload = this.section.payloadFromModel();
  }

  private async loadSection() {
    const [data, errors] = await easync(this.getStoreFrontSection(this.sectionId, this.showTag));

    if (data) {
      this.section = data;
    } else {
      this.section = null;
      showErrorToast();
    }
  }

  private async onSave() {
    const [data, errors] = await easync(
      this.updateStoreFrontSection(this.sectionId, this.payload, this.updateTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.section = data;
    this.feedPayload();
    showToast('Sezione aggiornata correttamente!');
  }

  private async addCategoryGroup() {
    this.attachCategoryGroup(this.selectedCategoryGroup, this.sectionId);
  }

  private removeCategoryGroup(value: StoreFrontCategoryGroup) {
    this.attachCategoryGroup(value.id, null);
  }

  private async openConfirmModal(value: StoreFrontCategoryGroup) {
    const message = "Sei sicuro di voler dissociare questo CategoryGroup?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.removeCategoryGroup(value);
    }
  }

  private async attachCategoryGroup(categoryGroupid: string, sectionId: string | null) {
    const payload = { storeFrontSectionId: sectionId };

    const [data, errors] = await easync(
      this.updateStoreFrontCategoryGroup(categoryGroupid, payload, StoreFrontCategoryGroupsMixinTags.Update)
    );

    if (errors) {
      showErrorToast();
      return
    }

    showToast('CategoryGroup aggiunto con successo!');
    this.categoryGroupList.loadCategoryGroups();
    this.categoryGroupSelect.loadItems();
  }
}
