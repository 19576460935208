

























import { Component, Mixins, Prop } from "vue-property-decorator";
import { OrderLine, Source } from "@/models";
import { namespace } from "vuex-class";
import PrintSideSelect from "@/components/PrintSide/Select.vue";
import OrderLineDesign from "@/components/OrderLine/Design.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import OrderLinesMixin from "@/mixins/http/OrderLinesMixin";
import { showErrorToast, showToast } from "@/utils/Toast";
import FileUploader from "@/components/FileUploader.vue";
import { Design } from "@/models";
import { easync } from "@/utils/http";

const createTag = "create_design";

@Component({
  components: {
    FileUploader,
    OrderLineDesign,
    PrintSideSelect
  },
})
export default class AddOrderLineDesignModal extends Mixins(OrderLinesMixin) {
  @Prop() readonly value!: OrderLine;
  private orderLine: OrderLine = this.value;

  private file: any = null;
  private printSideId: any = null;

  onDesignsChanged(orderLine: OrderLine) {
    this.orderLine = orderLine;
    this.$emit("changed", orderLine);
  }

  onPrintSideSelected(id: string){
    this.printSideId = id;
  }

  async create() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("design", this.file.file);
      formData.append("print_side_id", this.printSideId);

      const [data, errors] = await easync(this.createDesign(this.orderLine.id, formData, createTag));
      if (errors) {
        showErrorToast("Errore nella creazione del design!");
        return;
      }

      this.file = null;
      showToast("Design creato!");
      this.$emit("changed", data);
    });
  }
}
