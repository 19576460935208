




















































import OrderLineWarehouseItemMixin, { OrderLineWarehouseItemMixinTags } from '@/mixins/http/OrderLineWarehouseItemsMixin';
import OrderLineWarehouseItem from '@/models/OrderLineWarehouseItem';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import { Mixins, Component, Prop, Ref } from 'vue-property-decorator';
import ConfirmModal from '@/components/ConfirmModal.vue';
import WarehouseItemSelect from '@/components/WarehouseItem/Select.vue';

@Component({
  components: {
    ConfirmModal,
    WarehouseItemSelect
  }
})
export default class OrderLineWarehouseItemItem extends Mixins(OrderLineWarehouseItemMixin) {
  @Prop({ required: true }) readonly value!: OrderLineWarehouseItem;
  @Prop({ required: true }) readonly orderLineId!: string;

  private item: OrderLineWarehouseItem | null = null;
  private quantity: number | string = 1;

  @Ref() readonly confirmModal!: ConfirmModal;

  created() {
    this.item = this.value ? this.value : null;
    this.quantity = this.item.quantity;
  }

  get createMode() {
    return !this.item.id;
  }

  get quantityChanged() {
    return parseInt(this.quantity as string) !== this.item.quantity;
  }

  private async onSave() {
    this.createMode ? await this.onCreate() : await this.onUpdate();
  }

  private async onCreate() {
    const payload = {
      orderLineId: this.orderLineId,
      warehouseItemId: this.item.warehouseItemId,
      quantity: this.quantity || 1
    };

    const [data, errors] = await easync(
      this.createOrderLineWarehouseItem(payload, OrderLineWarehouseItemMixinTags.CREATE)
    );

    if (errors) {
      showErrorToast('Si è verificato un errore durante la creazione');
    } else {
      showToast('Item creato con successo!');
      this.$emit('created');
    }
  }

  private async onUpdate() {
    const payload = { quantity: this.quantity };

    const [data, errors] = await easync(
      this.updateOrderLineWarehouseItem(this.item.id, payload, OrderLineWarehouseItemMixinTags.UPDATE)
    );

    if (data) {
      showToast('Item per questa linea d\'ordine aggiornato con successo!');
      this.item.quantity = data.quantity;
    }
  }

  private async onDelete() {
    const [data, errors] = await easync(
      (this.confirmModal as any).confirm('Sicuro di voler eliminare questo item?')
    );

    if (data) {
      this.deleteItem();
    }
  }

  private async deleteItem() {
    const [data, errors] = await easync(
      this.destroyOrderLineWarehouseItem(this.item.id, OrderLineWarehouseItemMixinTags.DESTROY)
    );

    if (data) {
      this.$emit('destroyed');
    } else {
      showErrorToast("Si è verificato un errore con la cancellazione dell'item");
    }
  }
}
