
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_WAREHOUSE_ITEM_URL = BASE_API_URL+"warehouse_items"

@Component
export default class WarehouseItemsMixin extends Vue {

  getWarehouseItems(data: any, tag: string) {
    return this.$httpGetQuery( BASE_WAREHOUSE_ITEM_URL, tag, data);
  }

  getWarehouseItem(id: number, tag: string) {
    return this.$httpGet(BASE_WAREHOUSE_ITEM_URL+"/"+id, tag);
  }

  getWarehouseDiffStats(id: number, data: any, tag: string) {
    return this.$httpGetQuery(BASE_WAREHOUSE_ITEM_URL+"/diff_stats", tag, data);
  }

  destroyWarehouseItem(id: string|number, tag: string) {
    return this.$httpDelete(BASE_WAREHOUSE_ITEM_URL+"/"+id, tag);
  }

  createWarehouseItem(data: any, tag: string) {
    return this.$httpPost(BASE_WAREHOUSE_ITEM_URL, tag, data);
  }

  updateWarehouseItem(data: any, tag: string) {
     return this.$httpPut(BASE_WAREHOUSE_ITEM_URL+"/"+data.id, tag, data);
  }
}
