
















import { Component, Prop, Mixins } from "vue-property-decorator";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import RoundThumb from "@/components/RoundThumb.vue";
import { Order, OrderLine } from "@/models";
import DesignsModal from "@/components/OrderLine/DesignsModal.vue";
import AddDesignModal from "@/components/OrderLine/AddDesignModal.vue";
import OrderLinesMixin, { OrderLineMixinTags } from "@/mixins/http/OrderLinesMixin";
import { easync } from "@/utils/http";
import OrderLineItem from '@/components/OrderLine/Item.vue';

@Component({
  components: {
    DataTable,
    AddDesignModal,
    DesignsModal,
    RoundThumb,
    OrderLineItem
  }
})
export default class List extends Mixins(OrderLinesMixin) {
  @Prop({required: true}) readonly value: Order;

  private order: Order = null;

  private orderLines: OrderLine[] = [];

  async onDesignsChanged(orderLine: OrderLine) {
    await this.loadOrderLines();
    this.$emit('imported', this.orderLines.every((o) => o.importStatus === 'imported'));
  }

  async onDesignAdded(orderLine: OrderLine) {
    await this.loadOrderLines();
    this.$emit('imported',this.orderLines.every((o) => o.importStatus === 'imported'));
  }

  onOrderChanged(order: Order){
    this.$emit("orderChanged", order);
  }

  async created() {
    this.order = this.value;
    await this.loadOrderLines();
  }

  private async loadOrderLines() {
    const payload = {
      per: 'all',
      filter: {
        order: this.order.id
      },
      include: 'order_line_warehouse_items.warehouse_item.name,designs'
    }
    const [data, errors] = await easync(this.getOrderLines(payload, OrderLineMixinTags.INDEX));
    if (data) {
      this.orderLines = data;
    } else {
      this.orderLines = [];
    }
  }

  checkIfAllApproved() {
    this.$emit(
      "allApproved",
      this.value.orderLines.every(o => o.approved)
    );
  }

  async changeApprovedStatus() {
    await this.loadOrderLines();
    this.checkIfAllApproved();
  }
}
