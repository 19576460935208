import Model from "./Model";
import WarehouseItem from "./WarehouseItem";

export default class OrderLineWarehouseItem extends Model{
  static type = "orderLineWarehouseItems";

  orderLineId!: string;
  warehouseItemId!: string;
  quantity!: number;

  warehouseItem!: WarehouseItem;
}
