












































import { Component, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import { OrderLine, Source } from "@/models";
import { namespace } from "vuex-class";
import PrintSideSelect from "@/components/PrintSide/Select.vue";
import OrderLineDesign from "@/components/OrderLine/Design.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import OrderLinesMixin from "@/mixins/http/OrderLinesMixin";
import { showErrorToast, showToast } from "@/utils/Toast";
import FileUploader from "@/components/FileUploader.vue";
import { Design } from "@/models";
import { easync } from "@/utils/http";

const createTag = "create_design";

@Component({
  components: {
    VModal,
    FileUploader,
    OrderLineDesign,
    PrintSideSelect
  },
})
export default class AddOrderLineDesignModal extends Mixins(OrderLinesMixin) {
  modal!: VModal;
  private orderLine: OrderLine = null;

  @RegisterHttp(createTag) createRequest: RequestData;

  private index = 0;

  private file: any = null;
  private printSideId: any = null;

  onDesignsChanged(orderLine: OrderLine) {
    this.orderLine = orderLine;
    this.$emit("changed", orderLine);
  }

  onPrintSideSelected(id: string){
    this.printSideId = id;
  }

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  onHidden() {
    this.orderLine = null;
    this.index = 0;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    this.modal.hide();
  }

  protected onConfirm() {
    this.modal.hide();
  }

  async create() {
    let formData = new FormData();

    this.$nextTick(async () => {
      formData.append("design", this.file.file);
      formData.append("print_side_id", this.printSideId);

      const [data, errors] = await easync(this.createDesign(this.orderLine.id, formData, createTag));
      if (errors) {
        showErrorToast("Si è verificato un errore");
        return;
      }

      this.file = null;
      showToast("Design creato!");
      this.$emit("changed", data);
      this.onCancel();
    });
  }

  open(orderLine: OrderLine) {
    if (orderLine) {
      this.orderLine = orderLine;
    }
    this.modal.show();
  }
}
