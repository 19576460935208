









































import { Mixins, Component, Ref, Prop } from 'vue-property-decorator';
import { RegisterHttp } from '@/utils/Decorators';
import ConfirmModal from '@/components/Order/PreviewModal.vue';
import { RequestData } from '@/store/types/RequestStore';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
import DataTable from '@monade/vue-components/src/components/DataTable.vue';
import StoreFrontCategoryGroupsMixin, { StoreFrontCategoryGroupsMixinTags } from '@/mixins/http/StoreFrontCategoryGroupsMixin';
import { StoreFrontCategoryGroup } from '@/models';

@Component({
  components: {
    ConfirmModal,
    DataTable
  }
})
export default class StoreFrontCategoryGroupList extends Mixins(StoreFrontCategoryGroupsMixin) {
  @Prop({ default: null }) readonly filter!: any;

  private categoryGroups: StoreFrontCategoryGroup[] = [];

  @RegisterHttp(StoreFrontCategoryGroupsMixinTags.Index) readonly indexRequest!: RequestData;

  created() {
    this.loadCategoryGroups();
  }

  get columns() {
    return [
      { name: "name", label: "Nome" },
      { name: "actions", label: "" }
    ];
  }

  get payload() {
    const payload: any = {};

    if (this.filter) {
      payload.filter = this.filter;
    }

    return payload;
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  async loadCategoryGroups() {
    const [data, errors] = await easync(
      this.getStoreFrontCategoryGroups(this.payload, StoreFrontCategoryGroupsMixinTags.Index)
    );

    if (data) {
      this.categoryGroups = data;
    } else {
      this.categoryGroups = [];
      showErrorToast('Si è verificato un problema');
    }
  }

  private onSelected(value: StoreFrontCategoryGroup) {
    this.$emit('selected', value);
  }

  private onDelete(value: StoreFrontCategoryGroup) {
    this.$emit('delete', value);
  }
}
