import { Component, Vue } from 'vue-property-decorator';

import { BASE_API_URL } from '@/utils/Constants';

const BASE_SF_CATEOGRY_GROUPS_URL = BASE_API_URL + "store_front_category_groups";

export enum StoreFrontCategoryGroupsMixinTags {
  Index = 'StoreFrontCategoryGroupIndex',
  Show = 'StoreFrontCategoryGroupShow',
  Update = 'UpdateStoreFrontCategoryGroup',
  Create = 'CreateStoreFrontCategoryGroup',
  Destroy = 'DestroyStoreFrontCategoryGroup'
}

@Component
export default class StoreFrontCategoryGroupsMixin extends Vue {
  getStoreFrontCategoryGroups(data: any, tag: string) {
    return this.$httpGetQuery(BASE_SF_CATEOGRY_GROUPS_URL, tag, data);
  }

  getStoreFrontCategoryGroup(id: number | string, tag: string) {
    return this.$httpGet(BASE_SF_CATEOGRY_GROUPS_URL + "/" + id, tag);
  }

  createStoreFrontCategoryGroup(data: any, tag: string) {
    return this.$httpPost(BASE_SF_CATEOGRY_GROUPS_URL, tag, data);
  }

  updateStoreFrontCategoryGroup(id: number | string, data: any, tag: string) {
    return this.$httpPut(BASE_SF_CATEOGRY_GROUPS_URL + "/" + id, tag, data);
  }

  destoryStoreFrontCategoryGroup(id: number | string, tag: string) {
    return this.$httpDelete(BASE_SF_CATEOGRY_GROUPS_URL + "/" + id, tag);
  }
}
