import { BASE_API_URL } from "@/utils/Constants";
import { Component, Vue } from "vue-property-decorator";

const BASE_ORDER_URL = BASE_API_URL + "orders";

export enum OrderTags {
  Index = "OrderIndex",
  Import = "OrderImport",
  Reimport = "OrderReimport",
  Proxy = "OrderProxy"
}

@Component
export default class OrdersMixin extends Vue {
  getOrders(data: any, tag: string) {
    return this.$httpGetQuery(BASE_ORDER_URL, tag, data);
  }

  getOrder(id: number, tag: string) {
    const url = BASE_ORDER_URL + "/" + id
    return this.$httpGet(url, tag);
  }

  destroyOrder(id: string | number, tag: string) {
    const url = BASE_ORDER_URL + "/" + id
    return this.$httpDelete(url, tag);
  }

  createOrder(data: any, tag: string) {
    return this.$httpPost(BASE_ORDER_URL, tag, data);
  }

  generateOrderLabel(data: any, tag: string) {
    const url = BASE_ORDER_URL + "/" + data.id + "/generate_label"
    return this.$httpPost(url, tag, data);
  }

  reimportOrder(data: any) {
    const url = BASE_ORDER_URL + "/" + data.id + "/reimport"
    const tag = OrderTags.Reimport+data.id
    return this.$httpPost(url,tag,data);
  }

  proxyResource(id: any) {
    const url = BASE_ORDER_URL + "/" + id + "/source_resource"
    const tag = OrderTags.Proxy+id
    return this.$httpGet(url,tag);
  }


  importOrder(data: any) {
    const url = BASE_ORDER_URL + "/import"
    const tag = OrderTags.Import+data.externalId
    return this.$httpPost(url,tag,data);
  }

  getOrderStats(data: any, tag: string) {
    const url = BASE_ORDER_URL + "/stats"
    return this.$httpPost(url,tag, data);
  }

  updateOrder(data: any, tag: string) {
    return this.$httpPut(
      BASE_ORDER_URL + "/" + data.id,
      tag,
      data
    );
  }
}
